<template>
  <div class="government">
    <div class="header">
      <div class="header-img">
        <div style="display: flex;" @click="jumpToHome">
          <div>
            <img style="width:0.75rem;height:0.75rem;object-fit:cover;" src="../../static/images/home-b-t.png" alt="">
          </div>
          <div style="margin-top: 0.1rem;">
            <img style="width:1.47rem;height:0.49rem;object-fit:cover;vertical-align: super;margin-left: 0.1rem;" src="../../static/images/home-b-a.png" alt="">
          </div>
        </div>
        <div class="right" @click="jumpToHome1">
          <img style="width:0.68rem;height:0.57rem;object-fit:cover;" src="../../static/images/home-s-b.png" alt="">
        </div>
      </div>
      <div class="header-text">THE SOLUTION</div>
      <div class="sub-text">{{$t('m.business_1')}}</div>
    </div>
    <div class="list-box">
        <div class="wrap-img-box" v-for="item in list" :key="item.name" @click="jumpTo(item.name)">
          <img class="wrap-img" :src="item.img" :alt="item.title" />
          <div class="wrap-img-text">{{ item.name }}</div>
        </div>
      </div>
    <div class="wrap">
      <div>
        <div style="margin-bottom:0.32rem;">
          <span style="font-size: 0.56rem;font-weight: 400;color: #27201B;">SOLUTION</span>
          <span style="font-size: 0.43rem;font-weight: 400;color: #27201B;">·解决方案</span>
        </div>
        <div class="common-text">{{$t('m.home.solution')}}</div>
      </div>
      <div style="margin-top:1.6rem;">
        <img style="height:6.17rem;width:100%;object-fit:cover;margin-bottom:0.7rem;" src="../../static/images/d-zhsq.png" alt="">
        <div class="text-title">
          <div class="num">01</div>
          <div style="line-height:0.8rem;">
            <div style="font-size: 0.56rem;font-weight: 400;color: #27201B;">智慧社区解决方案</div>
            <div style="font-size: 0.4rem;font-weight: 400;color: #27201B;">安全、便捷、宜居、智能、生态</div>
          </div>
        </div>
        <div class="common-text">
          智慧社区是社区管理的一种新理念，是新形势下社会管理创新的一种新模式。
        </div>
        <div class="common-text" style="margin-top:0.6rem;">
智慧社区是指充分利用物联网、云计算、移动互联网等新一代信息技术的集成应用，通过全面数字化、智能化建设为社区居民提供一个安全、舒适、便利的现代化、智慧化生活环境，从而形成基于信息化、智能化社会管理与服务的一种新的管理形态的社区。
        </div>
      </div>
      <div style="margin-top:1.6rem;">
        <img style="height:6.17rem;width:100%;object-fit:cover;margin-bottom:0.7rem;" src="../../static/images/d-zhxf.png" alt="">
        <div class="text-title">
          <div class="num">02</div>
          <div style="line-height:0.8rem;">
            <div style="font-size: 0.56rem;font-weight: 400;color: #27201B;">智慧消防解决方案</div>
            <div style="font-size: 0.4rem;font-weight: 400;color: #27201B;">提升消防意识、增强管理模式</div>
          </div>
        </div>
        <div class="common-text">
          近年来，信息技术飞速发展，现代城市信息化水平有了很大提高，逐步形成了“智慧城市”的建设理念。消防安全作为城市建设的重要一环，逐步形成与“智慧城市”相匹配的“智慧消防”
        </div>
        <div class="common-text" style="margin-top:0.6rem;">
          智慧消防解决方案通过对消防隐患的全态监控，结合应急联动保障，实现火灾隐患早发现，早消除。
        </div>
      </div>
      <div style="margin-top:1.6rem;">
        <img style="height:6.17rem;width:100%;object-fit:cover;margin-bottom:0.7rem;" src="../../static/images/d-zhcg.png" alt="">
        <div class="text-title">
          <div class="num">03</div>
          <div style="line-height:0.8rem;">
            <div style="font-size: 0.56rem;font-weight: 400;color: #27201B;">智慧城管解决方案</div>
            <div style="font-size: 0.4rem;font-weight: 400;color: #27201B;">感知、分析、检查、决策</div>
          </div>
        </div>
        <div class="common-text">
          智慧城管是基于网格化管理，以地理信息系统为核心的，新一代信息技术支撑、社会知识创新环境下的城市管理新模式。
        </div>
        <div class="common-text" style="margin-top:0.6rem;">
          通过，全面感知、泛在互联、应用融合等方式提高城市管理水平及效率，从而改善城市整体形象，提升城市管理科学化、精细化、智能化水平，推动城市管理向数字化、精细化、智慧化发展。。
        </div>
      </div>
      <div style="margin-top:1.6rem;">
        <img style="height:6.17rem;width:100%;object-fit:cover;margin-bottom:0.7rem;" src="../../static/images/d-zhyq.png" alt="">
        <div class="text-title">
          <div class="num">04</div>
          <div style="line-height:0.8rem;">
            <div style="font-size: 0.56rem;font-weight: 400;color: #27201B;">智慧园区解决方案</div>
            <div style="font-size: 0.4rem;font-weight: 400;color: #27201B;">安全、效率、体验</div>
          </div>
        </div>
        <div class="common-text">
          智慧园区数字化解决方案实现楼宇园区的数据融合，一体化管理以及智能应用。优化园区管理效率，提升企业、员工工作体验，保障园区安全。
        </div>
        <div class="common-text" style="margin-top:0.6rem;">
          智慧园区构建分为三大服务体系，通过分层建设，从内到外搭建可成长、可扩充，创造面向未来的智慧园区系统框架
          1.为园区（管委会/管理公司）提供内部运营管理体系；
          2.为园区（企业）提供园企服务体系；
        </div>
      </div>
      <div style="margin-top:1.6rem;">
        <img style="height:6.17rem;width:100%;object-fit:cover;margin-bottom:0.7rem;" src="../../static/images/d-zhjt.png" alt="">
        <div class="text-title">
          <div class="num">05</div>
          <div style="line-height:0.8rem;">
            <div style="font-size: 0.56rem;font-weight: 400;color: #27201B;">智慧交通解决方案</div>
            <div style="font-size: 0.4rem;font-weight: 400;color: #27201B;">安全、效率、体验</div>
          </div>
        </div>
        <div class="common-text">
          主要包括设备监测、交通态势、统计决策和诱导发布等模块，贯穿了交通数据从采集到发布的整个全生命周期，构建了一套基于大数据的城市智慧交通整体解决方案。大数据+交通事故管理分析系统，对交通流量、速度、天气、事故情况等数据进行关联性分析和统一建模，得到事故发生的风险概率分布态势。该态势随着城市交通流的变化和实时事故情况进行动态更新，并可对高危区域进行实时、针对性管控。
        </div>
      </div>
      <div style="margin-top:1.6rem;">
        <img style="height:6.17rem;width:100%;object-fit:cover;margin-bottom:0.7rem;" src="../../static/images/d-shzl.png" alt="">
        <div class="text-title">
          <div class="num">06</div>
          <div style="line-height:0.8rem;">
            <div style="font-size: 0.56rem;font-weight: 400;color: #27201B;">市域社会治理</div>
            <div style="font-size: 0.4rem;font-weight: 400;color: #27201B;">综合研判、群防群治</div>
          </div>
        </div>
        <div class="common-text">
          采用大数据技术对市域社会治理各维度数据进行深入挖掘和综合分析。深化社会治理、公共服务、民生服务、应急事件响应等应用，强化市域社会治理决策的前瞻性和科学性，使“经验治理”向“精准治理”跨越。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Government',
    data() {
      return {
        list: [
          { name: '智慧社区', img: require('../../static/images/zhsq.png') },
          { name: '智慧消防', img: require('../../static/images/zhxf.png') },
          { name: '智慧城管', img: require('../../static/images/zhcg.png') },
          { name: '智慧园区', img: require('../../static/images/zhyq.png') },
          { name: '智慧交通', img: require('../../static/images/zhjt.png') },
          { name: '市域治理', img: require('../../static/images/syzl.png') },
        ]
      }
    },
    methods: {
      jumpToHome() {
        this.$router.push('/')
      },
      jumpToHome1() {
        this.$router.push('/home2')
      },
      jumpTo(name) {
        if (name === '智慧社区') {
          this.$router.push('/details/community')
        } else if (name === '智慧消防') {
          this.$router.push('/details/fire')
        } else if (name === '智慧城管') {
          this.$router.push('/details/city')
        } else if (name === '智慧园区') {
          this.$router.push('/details/park')
        } else if (name === '智慧交通') {
          this.$router.push('/details/traffic')
        } else if (name === '市域治理') {
          this.$router.push('/details/society')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.header{
  position: relative;
  padding: 0.3rem 0.4rem 0 0.4rem;
  box-sizing: border-box;
  width: 100%;
  height: 6.05rem;
  background: url('../../static/images/mxzq.png');
  background-size: 100% 100%;
}
.header-img{
  display: flex;
  justify-content: space-between;
}
.header-text{
  width: 6.6rem;
  position: absolute;
  margin-left: -3.2rem;
  top: 38%;
  left: 50%;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 6px;
}
.sub-text{
  position: absolute;
  font-size: 0.27rem;
  width: 6.2rem;
  font-weight: 300;
  color: #FFFFFF;
  opacity: 0.7;
  top: 50%;
  left: 50%;
  margin-left: -3.1rem;
  margin-top: 0.2rem;
}
.wrap{
  padding: 0.58rem 0.4rem 0.9rem 0.4rem;
  box-sizing: border-box;
  background: #F9F9F9;
}
.wrap-img{
  width: 0.93rem;
  height: 0.93rem;
  object-fit: cover;
}
.list-box{
  padding-top: 0.56rem;
  box-sizing: border-box;
  background: #F9F9F9;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrap-img-box{
  width: 33.3%;
  margin-bottom: 0.66rem;
  text-align: center;
}
.wrap-img-text{
  margin-top: 0.15rem;
  font-size: 0.37rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}
.common-text{
  font-size: 0.35rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 0.54rem;
}
.text-title{
  height: 2rem;
  display: flex;
}
.num{
  font-size: 1.41rem;
  font-family: DINCond-Bold;
  font-weight: 400;
  color: #27201B;
  margin-right: 0.6rem;
  line-height: 1;
}
</style>